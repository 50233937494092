import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/abition.jpg";
import lawnCare from "../../Assets/Projects/lawn care.jpg";
import FirstPortfolio from "../../Assets/Projects/FirstPortfolio.jpg";
import discordify from "../../Assets/Projects/discordify.jpg";
import FlexIt from "../../Assets/Projects/flexItgym.jpg";
import amazon from "../../Assets/Projects/amazonclone.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={FlexIt}
              isBlog={false}
              title="FlexIt Gym"
              description=" FlexIt Gym is an example of a rective Gym website. Supports html, css, and js code with instant view of website."
          
              demoLink="https://flexit-gym.web.app"
            />
          </Col>
          

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={amazon}
              isBlog={false}
              title="Amazonia"
              description="An Amazon-inspired eCommerce app with product placements, reviews, and secure payments. Built with  stack react.js, Material-UI, and Firebase."
              
              demoLink="https://clone-41fb0.web.app"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={lawnCare}
              isBlog={false}
              title="casablanca lawn service"
              description="Casablanca lawn service is an example of lawn service buisness website. Built with  stack react.js, Material-UI, and Firebase."
          
              demoLink="https://casablancalawnservice.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={discordify}
              isBlog={false}
              title="Discordify"
              description="Personal Chat Room or Workspace to share resources and hangout with friends. Has features which allows user for realtime messaging. Built with react.js, Material-UI, and Firebase. "
             
              demoLink="https://discord-app-acf9e.web.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="AMBITION RP SERVER"
              description="The AMBITION RP Server is a dynamic platform that offers a semi-serious Grand Theft Auto (GTA) role-playing experience for individuals of all backgrounds. Built with  stack react.js, Material-UI, and Firebase."
          
              demoLink="https://ambitionrp-c5412.web.app/index"
            />
          </Col>
         
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={FirstPortfolio}
              isBlog={false}
              title="Portfolio"
              description="Online personal website built with react.js. Supports html, css, and js code with instant view of website."
              
              demoLink="https://portfolio-56e51.web.app/"              
            />
          </Col>
          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
